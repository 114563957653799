import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import { SOCReport, SOCReportType, newSOCReport } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useSOCReportQuery = (reportId: number) =>
  useQuery<SOCReport>({
    queryKey: ["soc-reports", reportId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/soc-reports/${reportId}/`, {
        signal,
      });
      return newSOCReport(response.data);
    },
  });

export const useSystemSOCReportsQuery = (systemId: number) =>
  useQuery<SOCReport[]>({
    queryKey: ["soc-reports", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/soc-reports/system/${systemId}/`, {
        signal,
      });
      return response.data.reports.map((report: unknown) =>
        newSOCReport(report),
      );
    },
  });

export const useCreateSOCReportMutation = (systemId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/soc-reports/system/${systemId}/`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["soc-reports"],
      });
    },
  });
};

interface ISOCReportData {
  csocAnalysis: string;
  cuecAnalysis: string;
  deviationAnalysis: string;
  name: string;
  periodEnd: Date;
  periodStart: Date;
  socType: SOCReportType;
}

export const useEditSOCReportMutation = (reportId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISOCReportData) => {
      await axios.put(`/v1/soc-reports/${reportId}/`, {
        ...data,
        periodEnd: formatISO(data.periodEnd, { representation: "date" }),
        periodStart: formatISO(data.periodStart, { representation: "date" }),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["soc-reports"],
      });
    },
  });
};

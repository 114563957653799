import {
  Dropzone,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import { useCreateSOCReportMutation, useSystemQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const UploadSOCReport = ({ systemId }: IProps) => {
  const { data: system } = useSystemQuery(systemId);
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } =
    useCreateSOCReportMutation(systemId);

  const onClick = async () => {
    try {
      await upload(files);
      addToast("SOC Report uploaded", "success");
      setLocation(`/systems/${systemId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter((file) => file.type === "application/pdf"),
    );
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: `/systems/${systemId}/`, label: system?.name ?? "" },
        ]}
        title="Upload SOC Report"
      />
      <Card>
        <CardContent>
          <Dropzone label="Add file(s)" onDrop={onDrop} />
          <List>
            {files.map((file) => (
              <ListItem
                key={file.name}
                secondaryAction={
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() =>
                      setFiles(files.filter((checkFile) => checkFile !== file))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={files.length === 0}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Upload
          </LoadingButton>
          <FormLinks links={[{ label: "Back", to: `/systems/${systemId}/` }]} />
        </CardActions>
      </Card>
    </>
  );
};

export default UploadSOCReport;

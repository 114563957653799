import {
  DateField,
  FormLinks,
  NumberField,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { SOCReportType } from "src/models";
import { useEditSOCReportMutation, useSOCReportQuery } from "src/queries";

const FormSchema = z.object({
  csocAnalysis: z.string().trim(),
  cuecAnalysis: z.string().trim(),
  deviationAnalysis: z.string().trim(),
  name: z.string().trim(),
  periodEnd: z.coerce.date(),
  periodStart: z.coerce.date(),
  socType: z.string(),
  totalCsocs: z.coerce.number().int(),
  totalCuecs: z.coerce.number().int(),
  totalDeviations: z.coerce.number().int(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  reportId: number;
}

const EditSOCReport = ({ reportId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const { data: report } = useSOCReportQuery(reportId);
  const [_, setLocation] = useLocation();
  const { mutateAsync: edit } = useEditSOCReportMutation(reportId);

  const defaultValues = {
    csocAnalysis: report?.csocAnalysis ?? "",
    cuecAnalysis: report?.cuecAnalysis ?? "",
    deviationAnalysis: report?.deviationAnalysis ?? "",
    name: report?.name ?? "",
    periodEnd: report?.periodEnd ?? new Date(),
    periodStart: report?.periodStart ?? new Date(),
    socType: report?.socType ?? SOCReportType.SOC_1_TYPE_2,
    totalCsocs: report?.totalCsocs ?? 0,
    totalCuecs: report?.totalCuecs ?? 0,
    totalDeviations: report?.totalDeviations ?? 0,
  };

  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [report !== undefined]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        ...data,
        socType: data.socType as SOCReportType,
      });
      addToast("Report updated", "success");
      setLocation(`/soc-reports/${reportId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Update SOC Report" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                autoComplete="off"
                fullWidth
                label="Name"
                name="name"
                required
              />
              <DateField
                autoComplete="off"
                fullWidth
                label="Period Start"
                name="periodStart"
                required
              />
              <DateField
                autoComplete="off"
                fullWidth
                label="Period End"
                name="periodEnd"
                required
              />
              <SelectField
                autoComplete="off"
                fullWidth
                label="Report SOC Type"
                name="socType"
                options={Object.values(SOCReportType).map((value) => ({
                  value,
                }))}
                required
              />
              <NumberField
                autoComplete="off"
                fullWidth
                label="Total CSOCs"
                multiline
                name="totalCsocs"
                required
              />
              <TextField
                autoComplete="off"
                fullWidth
                helperText="Please explain any that are relevant"
                label="CSOC Analysis"
                multiline
                name="csocAnalysis"
                required
              />
              <NumberField
                autoComplete="off"
                fullWidth
                label="Total CUECs"
                multiline
                name="totalCuecs"
                required
              />
              <TextField
                autoComplete="off"
                fullWidth
                helperText="Please explain any that are relevant"
                label="CUEC Analysis"
                multiline
                name="cuecAnalysis"
                required
              />
              <NumberField
                autoComplete="off"
                fullWidth
                label="Total Deviations"
                multiline
                name="totalDeviations"
                required
              />
              <TextField
                autoComplete="off"
                fullWidth
                helperText="Please explain any that are relevant"
                label="Deviation Analysis"
                multiline
                name="deviationAnalysis"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Update" />
              <FormLinks
                links={[{ label: "Back", to: `/soc-reports/${reportId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditSOCReport;

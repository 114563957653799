import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SOCReportRow from "src/pages/System/SOCReportRow";
import { useSystemSOCReportsQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const SOCReports = ({ systemId }: IProps) => {
  const { data: reports } = useSystemSOCReportsQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Period Start</TableCell>
              <TableCell>Period End</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((report) => (
              <SOCReportRow key={report.id} report={report} />
            )) ?? <SkeletonRow cols={5} />}
            {reports?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>No reports</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SOCReports;

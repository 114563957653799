import { z } from "zod";

export enum SOCReportType {
  SOC_1_TYPE_1 = "SOC_1_TYPE_1",
  SOC_1_TYPE_2 = "SOC_1_TYPE_2",
  SOC_1_TYPE_2_BRIDGE_LETTER = "SOC_1_TYPE_2_BRIDGE_LETTER",
}

const socReportSchema = z.object({
  id: z.number().int().positive(),
  csocAnalysis: z.string().nullable(),
  cuecAnalysis: z.string().nullable(),
  deviationAnalysis: z.string().nullable(),
  name: z.string(),
  periodEnd: z.coerce.date().nullable(),
  periodStart: z.coerce.date().nullable(),
  socType: z.nativeEnum(SOCReportType).nullable(),
  systemId: z.number(),
  systemName: z.string(),
  timestamp: z.coerce.date(),
  totalCsocs: z.number().int().nullable(),
  totalCuecs: z.number().int().nullable(),
  totalDeviations: z.number().int().nullable(),
  uploaderEmail: z.string(),
  uploaderId: z.number().int().positive(),
});

export type SOCReport = z.infer<typeof socReportSchema>;

export const newSOCReport = (data: unknown): SOCReport =>
  socReportSchema.parse(data);

import { Title, Value } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { useSOCReportQuery } from "src/queries";

interface IProps {
  reportId: number;
}

const SOCReport = ({ reportId }: IProps) => {
  const { data: report } = useSOCReportQuery(reportId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={`/soc-reports/${report?.id}/edit/`}>
          Edit
        </MenuItem>
        <MenuItem component="a" href={`/v1/soc-reports/${report?.id}/file/`}>
          View report
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          {
            to: `/systems/${report?.systemId}/`,
            label: report?.systemName ?? "",
          },
        ]}
        title={`SOC Report - ${report?.name}`}
      />
      <Card>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Report Type</TableCell>
                <TableCell>
                  <Value text={report?.socType} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Period Start</TableCell>
                <TableCell>
                  <Value date={report?.periodStart} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Period End</TableCell>
                <TableCell>
                  <Value date={report?.periodEnd} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total CSOCs</TableCell>
                <TableCell>
                  <Value int={report?.totalCsocs} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CSOC Analysis</TableCell>
                <TableCell>
                  <Value text={report?.csocAnalysis} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total CUECs</TableCell>
                <TableCell>
                  <Value int={report?.totalCuecs} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CUEC Analysis</TableCell>
                <TableCell>
                  <Value text={report?.cuecAnalysis} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Deviations</TableCell>
                <TableCell>
                  <Value int={report?.totalDeviations} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Deviation Analysis</TableCell>
                <TableCell>
                  <Value text={report?.deviationAnalysis} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>File name</TableCell>
                <TableCell>
                  <Value text={report?.name} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Uploaded</TableCell>
                <TableCell>
                  <Value dateTime={report?.timestamp} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reviewed by</TableCell>
                <TableCell>
                  <Value text={report?.uploaderEmail} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SOCReport;
